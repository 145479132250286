import React, { useEffect, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import Edit from '../../img/icon_edit.png';
import Check from '../../img/correta_icon.svg';
import Cancel from '../../img/delete_icon.svg';

import api from '../../services/api';

import './ticket.css';

export default function Ticket(props) {
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const [status, setStatus] = useState(props.ticket.status);
	const [resposta, setResposta] = useState(props.ticket.resposta ? props.ticket.resposta : '');
	const [respostaAntiga, setRespostaAntiga] = useState(props.ticket.resposta ? props.ticket.resposta : '');
	const [salvar, setSalvar] = useState(false);
	const [color, setColor] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingSemEnviar, setLoadingSemEnviar] = useState(false);
	const alertSwal = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000
	});

	useEffect(() => {
		setColor(showColor(status));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const openModal = opened => {
		if (!opened) {
			if (!salvar) {
				setResposta(respostaAntiga);
			} else {
				setSalvar(false);
				setRespostaAntiga(resposta);
				props.ticket.status = status;
				setColor(showColor(status));
			}
		}
		setOpen(opened);
	};

	const showArtigo = artigo => {
		switch (artigo.toLowerCase()) {
			case 'a13':
			case '1':
				return 'Art. 13 – Remoção dos dados da lista de contatos ativos.';
			case 'a15':
			case '2':
				return 'Art. 15 – Contato fora do horário permitido (de seg a sex, das 9h às 21h, sábados, das 10h às 16h).';
			case 'a15p3':
			case '3':
				return 'Art. 15, parágrafo 3º - Novo contato para a mesma oferta dentro do prazo de 60 dias após a recusa.';
			case 'a15p4':
			case '4':
				return 'Art. 15, parágrafo 4º - Realização de mais de 3 chamadas telefônicas no mesmo dia.';
			case 'a15p5':
			case '5':
				return 'Art. 15, parágrafo 5º - Realização de nova oferta de produto/serviço dentro do prazo de 6 (seis) meses após o cancelamento.';
			default:
				return artigo;
		}
	};

	const showObjetivo = objetivo => {
		switch (objetivo.toLowerCase()) {
			case '1':
				return 'Oferta de Produto/Serviço.';
			case '2':
				return 'Cobrança de Valores (Atividade não regulamentada pelo Código de Ética).';
			default:
				return objetivo;
		}
	};

	const showColor = status => {
		if (status.includes('respondido')) {
			return '#AEAEAE';
		} else if (status.includes('aberto')) {
			return '#38DD90';
		} else {
			return '#3E7EA9';
		}
	};

	const changeStatus = async e => {
		e.persist();
		setStatus(e.target.value);
	};

	const confirmStatus = async () => {
		try {
			await api.put('/changeStatus', {
				id_atendimento: props.ticket.id_atendimento,
				status: status
			});
			alertSwal.fire({
				type: 'success',
				title: 'Status Atualizado'
			});
			props.ticket.status = status
		} catch (err) {
			setStatus(props.ticket.status);
			alertSwal.fire({
				type: 'error',
				title: 'Erro ao Atualizar o status'
			});
		} finally {
			setEdit(!edit);
		}
	}

	const cancelStatus = () => {
		setStatus(props.ticket.status);
		setEdit(false);
	}

	const salvarEnviar = async () => {
		setLoading(true);
		try {
			await api.put('/answerTicket', {
				id_atendimento: props.ticket.id_atendimento,
				resposta: resposta
			});
			if (resposta) setStatus('respondido');
			setSalvar(true);
			setRespostaAntiga(resposta);
			alertSwal.fire({
				type: 'success',
				title: 'Salvo e enviado!'
			});
			setLoading(false);
		} catch (err) {
			alertSwal.fire({
				type: 'error',
				title: 'Problema ao salvar e enviar sua resposta sobre o ticket!'
			});
			setLoading(false);
		}
	};

	const salvarNaoEnviar = async () => {
		setLoadingSemEnviar(true);
		try {
			await api.put('/answerWithoutSendTicket', {
				id_atendimento: props.ticket.id_atendimento,
				resposta: resposta
			});
			if (resposta) setStatus('em analise');
			setSalvar(true);
			setRespostaAntiga(resposta);
			alertSwal.fire({
				type: 'success',
				title: 'Salvo'
			});
			setLoadingSemEnviar(false);
		} catch (err) {
			alertSwal.fire({
				type: 'error',
				title: 'Problema ao salvar sua resposta sobre o ticket!'
			});
			setLoadingSemEnviar(false);
		}
	};

	const show = () => {
		openModal(true);
	};

	const close = () => {
		openModal(false);
		cancelStatus()
	};

	return (
		<div className='container-ticket-body'>
			<div className='borda-ticket-status' style={{ background: color }} />
			<div className='container-ticket'>
				<div className='container-ticket-top'>
					<div className='container-ticket-left'>
						<div className='ticket-left-items'>
							<div className='text-atendimento'>ID do Atendimento: {props.ticket.id_atendimento}</div>
							<div className='container-ticket-data-empresa'>
								<div className='text-data'>
									Data: {moment(props.ticket.createdAt).format('DD/MM/YYYY')}
								</div>
								<div className='text-empresa'>Empresa: {props.ticket.empresa}</div>
							</div>
							{!edit ? (
								<div className='text-status'>
									Status:
									<span className='ticket-status-color' style={{ color: color }}>
										{status}
									</span>
									{open ? (
										<span className='ticket-status-edit'>
											<img src={Edit} alt='edit' onClick={() => setEdit(!edit)} />
										</span>
									) : null}
								</div>
							) : (
								<div className='ticket-alterar-status'>
									<div className='text-status'>
										Alterar Status:
										<select onChange={changeStatus} className='ticket-select' defaultValue={status}>
											<option className='ticket-select-option' value='em aberto'>
												em aberto
											</option>
											<option className='ticket-select-option' value='em analise'>
												em analise
											</option>
											<option className='ticket-select-option' value='respondido'>
												respondido
											</option>
										</select>
										<div className='container-options-status'>
											<img src={Check} onClick={confirmStatus} alt="check" className='img-status-alterar'/>
											<img src={Cancel} onClick={cancelStatus} alt="cancel" className='img-status-alterar' />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					{open ? (
						<div className='container-close-button' onClick={close}>
							<div className='close-button'></div>
						</div>
					) : (
						<div className='ticket-ver' onClick={show}>
							<div>ver +</div>
						</div>
					)}
				</div>
				{open ? (
					<div id={props.ticket._id} className='container-ticket-bottom'>
						<div className='container-ticket-linha'>
							<div className='ticket-linha' />
						</div>

						<div className='ticket-objetivo'>
							<div className='ticket-objetivo-1'>
								Objetivo da ligação que violou o Código de Ética do Probare:
							</div>
							<div className='ticket-objetivo-2'>{showObjetivo(props.ticket.objetivo)}</div>
						</div>
						<div className='ticket-artigo'>
							<div className='ticket-artigo-1'>Violação ao Código de Ética:</div>
							<div className='ticket-artigo-2'>{showArtigo(props.ticket.artigo)}</div>
						</div>

						<div className='ticket-dados-linha-1'>
							<div className='ticket-telefone'>
								<div className='ticket-telefone-1'>Telefone que Efetuou a Ligação:</div>
								<div className='ticket-telefone-2'>{props.ticket.telefone}</div>
							</div>
							<div className='ticket-empresa'>
								<div className='ticket-empresa-1'>Contatado em nome de:</div>
								<div className='ticket-empresa-2'>{props.ticket.empresa}</div>
							</div>
						</div>

						<div className='ticket-dados-linha-2'>
							<div className='ticket-data'>
								<div className='ticket-data-1'>Data de Contato:</div>
								<div className='ticket-data-2'>{props.ticket.data}</div>
							</div>
							<div className='ticket-horario'>
								<div className='ticket-horario-1'>Horário de contato:</div>
								<div className='ticket-horario-2'>{props.ticket.horario}</div>
							</div>
						</div>

						<div className='container-ticket-linha'>
							<div className='ticket-linha' />
						</div>

						<div className='container-ticket-form'>
							<div className='ticket-form-resposta'>Resposta</div>
							<div className='container-form-textfield'>
								<textarea
									className='form-textfield'
									placeholder='Digite aqui sua resposta a esta denúncia'
									value={resposta}
									onChange={e => setResposta(e.target.value)}
								/>
								<div className='container-form-button'>
									<button className='form-button-1' onClick={salvarNaoEnviar}>
										{loadingSemEnviar ? (
											<MoonLoader sizeUnit={'em'} size={1} color={'#FFFFFF'} />
										) : (
											'salvar resposta sem enviar'
										)}
									</button>
									<button className='form-button-2' onClick={salvarEnviar} disabled={loading}>
										{loading ? (
											<MoonLoader sizeUnit={'em'} size={1} color={'#FFFFFF'} />
										) : (
											'salvar e enviar'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
}
