const development = {
	url: `${process.env.REACT_APP_URL}`,
};
const production = {
	url: `${process.env.REACT_APP_URL}`,
};

const config = {
	development,
	production,
};

const env = process.env.NODE_ENV || 'development';

module.exports = config[env];