import React from 'react';
import './main.css';

import Login from '../login/login';
import Home from '../home/home';

import PrivateRoute from '../../routes/privateRoutes';
import AuthRoute from '../../routes/authRoutes';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

export default function main() {
	return (
		<Router>
			<Switch>
				<AuthRoute exact path='/' component={Login} />
				<PrivateRoute path='/home' component={Home} />
			</Switch>
		</Router>
	);
}
