import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MoonLoader } from 'react-spinners';

import { deleteToken, isAuthenticated } from '../../services/auth';
import api from '../../services/api';

import logo from '../../img/logo_probare.svg';
import logoFilterWhite from '../../img/icon_filter_white.svg';
import logoFilterBlack from '../../img/icon_filter_black.svg';

import Ticket from '../../components/ticket/ticket';

import './home.css';

export default function Home(props) {
	const [tickets, setTickets] = useState([]);
	const [filterTickets, setFilterTickets] = useState([]);
	const [openFiltro, setOpenFiltro] = useState(false);
	const [filter, setFilter] = useState('');
	const [status, setStatus] = useState('');
	const [data, setData] = useState(new Date());
	const [endData, setEndData] = useState(new Date().setDate(new Date().getDate() + 1));
	const [loading, setLoading] = useState(false);

	const alertSwal = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000
	});

	useEffect(() => {
		getTickets(status, data, endData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTickets = async (statusAux, dataAux, endDataAux) => {
		if (await isAuthenticated()) {
			api.get(
				`/tickets?status=${statusAux}
				&data=${moment(dataAux).format('YYYY-MM-DD')}
				&endData=${moment(endDataAux).format('YYYY-MM-DD')}`
			)
				.then(response => {
					setTickets(response.data);
					setFilterTickets(response.data);
				})
				.catch(err => {
					alertSwal.fire({
						type: 'error',
						title: 'Não foi Póssivel buscar seus tickets'
					});
				});
		} else {
			alertSwal.fire({
				type: 'error',
				title: 'Sem Autorização!'
			});
			setTimeout(() => {
				props.history.push('/');
			}, 1000);
		}
	};

	const getTicket = async () => {
		setLoading(true)
		if (await isAuthenticated()) {
			api.get(`/tickets/${filter}`)
				.then(response => {
					setTickets(response.data);
					setFilterTickets(response.data);
					if (response.data.length === 0)
						alertSwal.fire({
							type: 'error',
							title: 'Ticket não encontrado!'
						});
					else
						alertSwal.fire({
							type: 'success',
							title: 'Ticket Encontrado!'
						});
					setLoading(false)
				})
				.catch(err => {
					alertSwal.fire({
						type: 'error',
						title: 'Ticket não encontrado!'
					});
				});
		} else {
			alertSwal.fire({
				type: 'error',
				title: 'Sem Autorização!'
			});
			setTimeout(() => {
				props.history.push('/');
			}, 1000);
		}
	};

	const logout = () => {
		deleteToken();
		alertSwal.fire({
			type: 'success',
			title: 'Você foi deslogado com sucesso!'
		});
		setTimeout(() => {
			props.history.push('/');
		}, 1000);
	};

	const handleFilter = filter => {
		setFilter(filter)
		setFilterTickets(tickets.filter(ticket => ticket.id_atendimento.toLowerCase().includes(filter.toLowerCase())));
	};

	const filterWithStatus = statusAux => {
		setStatus(statusAux);
		getTickets(statusAux, data, endData);
	};

	const handleChangeData = dataAux => {
		setData(dataAux);
		getTickets(status, dataAux, endData);
	};

	const handleChangeDataEnd = dataAux => {
		const aux = new Date(dataAux).setDate(dataAux.getDate() + 1)
		setEndData(dataAux);
		getTickets(status, data, aux);
	};

	return (
		<div className='container-home'>
			<div className='home-header'>
				<div className='home-logo'>
					<img alt='logo' src={logo} />
				</div>
				<div className='home-container-filter-search'>
					<div className='home-container-filter'>
						<input
							className='home-container-filter-input'
							placeholder='Busca por ID do atendimento'
							onChange={e => handleFilter(e.target.value)}
						/>
						<div className='buttonFilter' onClick={() => setOpenFiltro(!openFiltro)}>
							<img src={logoFilterWhite} alt='filter' />
							<div className='arrowDown'></div>
						</div>
						{openFiltro ? (
							<div className='container-filter-tab'>
								<div className='title-filter-tab'>
									<img src={logoFilterBlack} alt='miniFilter' />
									Filtrar por
								</div>
								<div className='filter-tab-status'>
									<div className='text-status'>
										Status:
										<select
											className='filter-tab-select'
											defaultValue={status}
											onChange={e => filterWithStatus(e.target.value)}>
											<option className='filter-tab-select-option' value=''>
												todos
											</option>
											<option className='filter-tab-select-option' value='em aberto'>
												em aberto
											</option>
											<option className='filter-tab-select-option' value='em analise'>
												em analise
											</option>
											<option className='filter-tab-select-option' value='respondido'>
												respondido
											</option>
										</select>
									</div>
								</div>
								<div className='filter-tab-data'>
									Data:
									<DatePicker
										className='filter-tab-datepicker'
										dateFormat='dd/MM/yyyy'
										selected={data}
										onChange={handleChangeData}
										maxDate={endData}
									/>
									Até:
									<DatePicker
										className='filter-tab-datepicker'
										dateFormat='dd/MM/yyyy'
										selected={endData}
										onChange={handleChangeDataEnd}
									/>
								</div>
							</div>
						) : null}
					</div>
					<div className='button-filter-buscar'>
						<button disabled={loading} onClick={getTicket}>
							{loading ? <MoonLoader sizeUnit={'px'} size={15} color={'#FFFFFF'} /> : 'Buscar'}
						</button>
					</div>
				</div>
				<div className='home-container-sair'>
					<button onClick={logout}>Sair</button>
				</div>
			</div>
			<div className='home-body'>
				<div className='home-container-tickets'>
					<div className='home-box-text'>
						<div className='home-text-1'>Ouvidoria do Programa de Auto-Regulamentação</div>
						<div className='home-text-2'>GESTÃO DE DENÚNCIA</div>
					</div>
					<div className='home-tickets-word'>Tickets</div>
					<div className='home-box-tickets'>
						{filterTickets.map(ticket => (
							<Ticket key={ticket.id_atendimento} ticket={ticket} />
						))}
					</div>
				</div>
				<div />
				<div />
			</div>
		</div>
	);
}
